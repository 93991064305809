import { BoxStatus } from "../enums/boxStatus";

export function checkLetter(
  solution: string,
  word: string,
  pos: number
): BoxStatus {
  let letter = word[pos];
  if (solution.includes(letter)) {
    if (solution[pos] === letter) {
      return BoxStatus.correct;
    } else {
      if (word[solution.indexOf(letter)] === letter) {
        // se encuentra en otra posicion ya adivinada
        return BoxStatus.absent;
      }
      return BoxStatus.present;
    }
  } else {
    return BoxStatus.absent;
  }
}
