import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import styles from "./modalForgotPassword.module.scss";
import  FBAuth  from '../../services/firebase/auth';
import { closeOutline} from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../services/firebase/config';

interface ModalForgotPasswordProps{
    show: boolean;
    onClose: () => void;
    onRegister: () => void;
    onLogin: () => void;
}

const ModalForgotPassword: React.FC<ModalForgotPasswordProps> = ({show,onClose,onLogin,onRegister}:ModalForgotPasswordProps) => {   

    const [email, setEmail] = useState("");
    const [errorInfo, setErrorInfo] = useState({show:false,msg:""});  
    

    function dismiss() {        
        onClose();
    }
    
    return (
        <>      
        <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
            <IonToolbar>
                <IonTitle className={styles.modalTitle}>Register</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="light" onClick={() => dismiss()}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className={styles.content}>
            <IonItem>
                <IonLabel position="floating">Email Address</IonLabel>
                <IonInput
                    type="email"
                    onIonChange={(e:any) => {
                    setEmail(e.detail.value);
                    }}
                    name="email"
                />
                </IonItem>
                
                <div style={{ padding: 10, paddingTop: 20 }}>
                <IonButton
                    expand="full"
                    style={{ margin: 14 }}
                    onClick={() => sendPasswordResetEmail(auth,email).then(() => {
                        setErrorInfo({show:false,msg:"Please check your email for a password reset link."});
                    })}
                >Send password reset email
                </IonButton>
                
                <div onClick={()=>{onRegister()}}>
                Don't have an account? <Link to="">Register</Link> now.
                </div>
                </div>
            </IonContent>
        </IonModal> 
        <IonToast
              color="danger"
              isOpen={errorInfo.show}
              onDidDismiss={() => setErrorInfo({show:false,msg:""})}
              message={errorInfo.msg}
              duration={2000}
            />           
    </>
    );
    
};

export default ModalForgotPassword;