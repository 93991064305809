import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styles from "./modalLogin.module.scss";
import  FBAuth  from '../../services/firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebase/config';
import { closeOutline, logoGoogle } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

interface ModalLoginProps{
    show: boolean;
    onClose: () => void;
    onLogin: () => void;
    onRegister: () => void;
    onForgotPassword: () => void;
}

const ModalLogin: React.FC<ModalLoginProps> = ({show,onClose,onRegister,onLogin,onForgotPassword}:ModalLoginProps) => {   

    const [email, setEmail] = useState("test@test.com");
    const [password, setPassword] = useState("");

    const history = useHistory();   
    
    const [errorInfo, setErrorInfo] = useState({show:false,msg:""});  


    function dismiss() {        
        onClose();
    }
    
    return (
        <>      
        <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
            <IonToolbar>
                <IonTitle className={styles.modalTitle}>Login</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="light" onClick={() => dismiss()}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className={styles.content}>
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                type="email"
                onIonChange={(e:any) => {
                  setEmail(e.detail.value);
                }}
                name="email"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                type="password"
                onIonChange={(e:any) => {
                  setPassword(e.detail.value);
                }}
                name="password"
              />
            </IonItem>
            <div style={{ padding: 10, paddingTop: 20 }}>
              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={async () => {
                  let error = await FBAuth.logInWithEmailAndPassword(email, password)
                  if(error)
                    setErrorInfo({show:true,msg:error || ""})
                }}
              >
                Login
              </IonButton>
              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={(e) => {
                  e.preventDefault();
                  onRegister();
                }}
              >
                Create Account
              </IonButton>

              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={()=>{
                  FBAuth.signInWithGoogle().then((data)=>{
                    onLogin();
                  }).catch((error)=>{
                    setErrorInfo({show:true,msg:error})
                  })
                  }
                }
              >
                <IonIcon icon={logoGoogle}></IonIcon>  Login With Google
              </IonButton>
              <div onClick={onForgotPassword}>
                <Link to="">Forgot Password</Link>
              </div>              
            </div>
            <IonToast
              color="danger"
              isOpen={errorInfo.show}
              onDidDismiss={() => setErrorInfo({show:false,msg:""})}
              message={errorInfo.msg}
              duration={2000}
            />
            </IonContent>
        </IonModal> 
           
    </>
    );
    
};

export default ModalLogin;