import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/index";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const persistConfig = {
  key: 'root',
  storage,
}



const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [composedEnhancer]
})
 /*
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("../reducers", () => {
    const newRootReducer = require("../reducers").default;
    store.replaceReducer(newRootReducer);
  });
}
*/


export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export default store;
