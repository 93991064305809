
import { BoxStatus } from "../../enums/boxStatus";
import Box from "../Box/Box";
import styles from "../row.module.scss";

interface RowCurrentProps {
    word: string;
}

const RowCurrent: React.FC<RowCurrentProps> = ({ word }:RowCurrentProps) => {

    
    const wordArray = word.split("");
    
      return (
        <div className={styles.row}>
            {wordArray.map((letter, i) => (
                <Box key={i} value={letter} status={BoxStatus.edit} />
            ))}
            {Array.from(Array(5 - wordArray.length)).map((letter, i) => (
                <Box key={i} value={""} status={BoxStatus.empty} />
            ))}
        </div>
      );
}

export default RowCurrent;