export const PERFORM_LOGIN = "PERFORM_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const READY = "READY";
export const STARTING = "STARTING";
export const PLAYING = "PLAYING";
export const FINISHED_WON = "FINISHED_WON";
export const FINISHED_LOST = "FINISHED_LOST";
export const LOADER_SHOW = "LOADER_SHOW";
export const LOADER_HIDE = "LOADER_HIDE";
export const ALERT = 'ALERT';
export const CONFIG_UPDATE='CONFIG_UPDATE';