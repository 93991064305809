import {
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import {
  closeOutline,
  logoFacebook,
  logoInstagram,
  logoTwitch,
  logoTwitter,
  mailOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { auth } from "../../services/firebase/config";
import styles from "./shareResults.module.scss";

import BoxCompleted from "../BoxCompleted/BoxCompleted";
import { checkLetter } from "../../helpers/checkLetter";
import { useSelector } from "react-redux";
import RowCurrent from "../RowCurrent/RowCurrent";
import TimerDynamite from "../TimerDynamite/TimerDynamite";
import { Share } from "@capacitor/share";
import { useGenerateShareText } from "../../hooks/useGenerateText";


const ShareResults: React.FC = () => { 
 
  const solution = useSelector((states: any) => {
    return states.game.wordToGuess;
  });

  const completedWords: string[] = useSelector((states: any) => {
    return states.game.completedWords || [];
  });

  const {text} = useGenerateShareText();

  async function share() {
    await Share.share({
      title: 'Look at this!',
      text: text,
      url: 'http://explodle.com/',
      dialogTitle: 'Share my new achievement',
    });
  }

  const arr = Array.from(Array(5));

  return (
    <>
      <IonGrid className={styles.statisticsRow}>
        <IonRow key={0}>
          Explodle
        </IonRow>
        <IonRow key={1}>
          <div className={styles.mainContainer}>
            {completedWords.map((word, i) => (
              <div className={styles.row}>
                {arr.map((_, i) => (
                  <BoxCompleted
                    key={i}
                    value={""}
                    status={checkLetter(solution, word, i)}
                    animate={false}
                    pos={i}
                  />
                ))}
              </div>
            ))}
          </div>
        </IonRow>
        <IonRow key={2}>
          <RowCurrent key={1} word={solution} />
        </IonRow>
        <IonRow key={3}>
          <div className={styles.mainContainer}>
          <IonCol className={styles.buttonCol}>
            <IonButton onClick={share}>
              <IonIcon icon={logoFacebook} />
            </IonButton>
          </IonCol>
          <IonCol className={styles.buttonCol}>
            <IonButton onClick={share}>
              <IonIcon icon={logoInstagram} />
            </IonButton>
          </IonCol>
          <IonCol className={styles.buttonCol}>
            <IonButton onClick={share}>
              <IonIcon icon={logoTwitter} />
            </IonButton>
          </IonCol>
          <IonCol className={styles.buttonCol}>
            <IonButton onClick={share}>
              <IonIcon icon={mailOutline} />
            </IonButton> 
          </IonCol>
          </div>
          
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ShareResults;
