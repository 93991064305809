import { BoxStatus } from "../../enums/boxStatus";
import Box from "../Box/Box";
import styles from "../row.module.scss";



const RowEmpty: React.FC = () => {

    const arr = Array.from(Array(5));

    

      return (
        <div className={styles.row}>
          {arr.map((_, i) => (
            <Box
              key={i}
              value={""}
              status={BoxStatus.empty}
              animate={true}
              pos={i}
            />
          ))}
        </div>
      );
}

export default RowEmpty;