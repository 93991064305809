

import React, { useEffect, useState } from 'react'; 
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import styles from './pie.module.scss';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebase/config';
import { useDocument } from 'react-firebase-hooks/firestore';

import {  doc } from 'firebase/firestore';
import {  db } from "../../services/firebase/config";

ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);

interface StatisticsPieChartProps{
  uid:string;
}

const StatisticsPieChart: React.FC<StatisticsPieChartProps> = ({uid}:StatisticsPieChartProps) => {

  const [userData] = useDocument(doc(db, "users",uid || "default"),{});
  const [statistics,setStatistics] = useState<any>();

  useEffect(() => { 
    
    setStatistics(userData?.data()?.statistics);
  },[userData]) 

  const colorsOrder = [
    '#C8F9E8',
    '#026E78',
    '#038C8C',
    '#31BAAE',
    '#5BDCC6',
    '#94F3DA',
  ]

  const data = {
    labels: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'],
    datasets: [
      {
        data: [
          Number(statistics?.inOneTurn*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0), 
          Number(statistics?.inTwoTurns*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0), 
          Number(statistics?.inThreeTurns*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0), 
          Number(statistics?.inFourTurns*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0), 
          Number(statistics?.inFiveTurns*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0), 
          Number(statistics?.inSixTurns*100/(statistics?.totalWins + statistics?.totalLosses)).toFixed(0)
        ],
        backgroundColor:colorsOrder,
        borderColor: colorsOrder,     
        borderWidth: 1,
      },        
    ],    
}
  
const options:any ={
  layout: {
    padding: {
        left: 50,
        right: 50,
        top: 50,
        bottom: 50
    }
},
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        offset:0,
        align: 'end',
        anchor: 'end',
        color: function(context:any) {
          if(context)
            return colorsOrder[context.dataIndex];
        }, 
        font: {
					weight: 700,
          size: 13,
				},
        formatter: function(value:any, context:any) {
          
          if(Math.round(value) > 0)
            return Number(context.dataIndex+1) + ': ' + Math.round(value) + '%';
          else
            return ''
        }, 
      }
    }

}

  
   

  return( 
      <div className={styles.pie}>
        <Pie data={data} options={options}/>
      </div>
  )

}

export default StatisticsPieChart;