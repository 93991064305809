import {
  IonAvatar,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, cogOutline, cogSharp, heartOutline, heartSharp, helpOutline, helpSharp, logOutOutline, logOutSharp, mailOutline, mailSharp, navigate, paperPlaneOutline, paperPlaneSharp, peopleOutline, peopleSharp, personOutline, personSharp, statsChartOutline, statsChartSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import  FBUser  from '../services/firebase/user';
import  FBAuth  from '../services/firebase/auth';
import { auth } from '../services/firebase/config';
import { useDispatch, useSelector } from 'react-redux';
import {  closeLogin, doLogin, ready } from '../redux/actions';
import ModalLogin from './ModalLogin/ModalLogin';
import ModalStatistics from './ModalStatistics/ModalStatistics';
import styles from './wordle.module.scss';
import ModalRegister from './ModalRegister/ModalRegister';
import ModalForgotPassword from './ModalForgotPassword/ModalForgotPassword';
import { READY } from '../redux/constants/action-types';

interface AppPage {
  url?: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  click: () => void;
}



const Menu: React.FC = () => {
  const [showLogin, setShowLogin] = useState<boolean>(false); 
  const [showRegister, setShowRegister] = useState<boolean>(false); 
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false); 

  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const previousState = useSelector((states: any) => {  
    return states.game.previousState;
  });
  
  const actualState = useSelector((states: any) => {  
    return states.game.status
  }); 

  const isReady = useSelector((states: any) => {  
    console.log(states.game);
    return states.game.status === READY
  }); 
  
const appPages: AppPage[] = [
  {
    title: 'Help',
    url: '/page/Help',
    iosIcon: helpOutline,
    mdIcon: helpSharp,
    click: () => {}
  },
  {
    title: 'Statistics',
    url: '/page/statistics',
    iosIcon: statsChartOutline,
    mdIcon: statsChartSharp,
    click: () => {}
  },
  {
    title: 'Configurations',
    url: '/page/config',
    iosIcon: cogOutline,
    mdIcon: cogSharp,
    click: () => {}
  }
];

  useEffect(() => {
    if(user){
      FBUser.fetchUserName(user).then(data=>{
        setName(data);
        setImgUrl(user?.photoURL || '');
      })
    }
  }, [user, loading]);

  function openLogin() {
    if(isReady){
      setShowLogin(true)
      dispatch(doLogin(actualState))
    }
    else{
      alert("You need to finish the game before you can login")
    }
    
  }

  function finishLogin() {
    console.log(previousState)
    dispatch(closeLogin(previousState))
  }
  //return (<></>)

  return (
    <>
    <ModalLogin show={showLogin} 
      onClose={()=>{ setShowLogin(false) ; finishLogin()}}
      onLogin={()=>{ setShowLogin(false) }}
      onRegister={()=>{setShowLogin(false); setShowRegister(true)}}
      onForgotPassword={()=>{setShowLogin(false); setShowForgotPassword(true);}}
    />   

    <ModalRegister show={showRegister} 
      onClose={()=>{ setShowRegister(false) ; finishLogin()}}
      onLogin={()=>{setShowLogin(true); setShowRegister(false)}}
      onRegister={()=>{setShowRegister(false); setShowForgotPassword(true);}}
    />  

    <ModalForgotPassword show={showForgotPassword} 
      onClose={()=>{ setShowForgotPassword(false) ; finishLogin()}}
      onLogin={()=>{setShowForgotPassword(true); setShowLogin(false)}}
      onRegister={()=>{setShowForgotPassword(false); setShowRegister(true)}}
    /> 

    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          {user?<IonListHeader> 
            <IonGrid>
              <IonAvatar>
                <img src={imgUrl} />
              </IonAvatar>
              <IonRow><h3>{name}</h3></IonRow>
              <IonRow><span className={styles.email}>{user?.email}</span></IonRow>
            </IonGrid>
          </IonListHeader>:null}
          {!user?<IonMenuToggle autoHide={false}>
            <IonItem  onClick={()=>{
              openLogin();
            }} routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" icon={cogOutline} />
              <IonLabel>Login</IonLabel>
            </IonItem>
          </IonMenuToggle>:
          <IonMenuToggle autoHide={false}>
            <IonItem   onClick={() => {
                  console.log('logout');	
                  dispatch(ready(undefined))
                  FBAuth.logout()
                }} lines="none" detail={false}>
              <IonIcon slot="start" icon={cogOutline} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>}
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} onClick={appPage.click} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>        
      </IonContent>
    </IonMenu>
    </>
    
  );
};

export default Menu;
