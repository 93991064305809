import { IonIcon } from "@ionic/react";
import { backspace, backspaceOutline } from "ionicons/icons";
import styles from "./keyboard.module.scss";
import classNames from "classnames";
import Key from "./key";
import { LetterStatus } from "../../enums/letterStatus";
import { useSelector } from "react-redux";
import BackSpace from "../../assets/backspace.png";

const classes = classNames.bind(styles);

interface KeyboradProps {
  keys: string[];
  onKeyPressed: (key: string) => void;
}

const Keyboard: React.FC<KeyboradProps> = ({
  keys,
  onKeyPressed,
}: KeyboradProps) => {
  const solution = useSelector((states: any) => {
    return states.game.wordToGuess || "";
  });

  const completedWords: string[] = useSelector((states: any) => {
    return states.game.completedWords || [];
  });

  let keysPressed: string[] = [];

  function handleInput(e: any) {
    keysPressed.push(e.target.textContent);
    onKeyPressed(e.target.textContent);
  }

  function handleEnter(e: any) {
    onKeyPressed("ENTER");
  }

  function handleDelete(e: any) {
    onKeyPressed("BACKSPACE");
  }

  function checkKeyStatus(letter: string): LetterStatus {
    let bestStatus: LetterStatus = LetterStatus.clear;

    for (let word of completedWords) {
      let find = -1;
      for (let i = 0; i < word.length; i++) {
        if (word[i] === letter) {
          find = i;
        }
      }

      if (find >= 0) {
        bestStatus = LetterStatus.absent;
        if (solution.includes(letter)) {
          bestStatus = LetterStatus.present;
          if (solution[find] === letter) {
            bestStatus = LetterStatus.correct;
            return bestStatus;
          }
        }
      }
    }

    return bestStatus;
  }

  return (
    <div className={styles.keyboardContainer}>
      <div className={styles.keyRow}>
        {Array.from(Array(10)).map((_, i) => (
          <Key
            key={i}
            value={keys[i]}
            onClick={handleInput}
            status={checkKeyStatus(keys[i])}
          />
        ))}
      </div>
      <div className={styles.keyRow}>
        <div className={styles.emptyKey}></div>
        {Array.from(Array(9)).map((_, i) => (
          <Key
            key={i + 10}
            value={keys[i + 10]}
            onClick={handleInput}
            status={checkKeyStatus(keys[i + 10])}
          />
        ))}
      </div>

      <div className={styles.keyRow}>
        {Array.from(Array(7)).map((_, i) => (
          <Key
            key={i + 19}
            value={keys[i + 19]}
            onClick={handleInput}
            status={checkKeyStatus(keys[i + 19])}
          />
        ))}
        <button className={styles.deleteKey} onClick={handleDelete}></button>
        <button className={styles.enterKey} onClick={handleEnter}>
          ENTER
        </button>
      </div>
    </div>
  );
};

export default Keyboard;
