const sounds =[
    {
        id:'burnFuseSound',
        audio:new Audio(require('../assets/fuse5.mp3'))        
    },
    {
        id:'explotion',
        audio:new Audio(require('../assets/explotion.mp3'))        
    },
    {
        id:'congrats',
        audio:new Audio(require('../assets/Congrats3.mp3'))        
    }
]

class Sounds {

    play(id:string,loop:boolean = false){
        try{
            let sound = sounds.find(sound => sound.id === id);  
            
            if(!sound)
                alert("Audio file is missing")     
                
            sound!.audio.play();  
            if(loop){
              sound!.audio.addEventListener('ended', function () {
                this.currentTime = 0;
                this.play();
              })   
            }

        }
        catch(err){
            alert(err)
        }
        
    }

    stop(id:string){
        try{
            let sound = sounds.find(sound => sound.id === id);
            sound!.audio.pause();   
        }
        catch(err){
            console.log(err)
        }     
    }

   

}

const _Sounds = new Sounds();
export default _Sounds;