
import React, { useEffect } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { timeout } from 'workbox-core/_private';
import { FINISHED_WON, PERFORM_LOGIN, PLAYING, READY, STARTING } from '../../redux/constants/action-types';
import ExplotionAnimation from './ExplosionAnimation/ExplotionAnimation';
import styles from './timerDynamite.module.scss';	
import Sounds from '../../services/sounds';
import { updateConfig } from '../../redux/actions';

interface TymerDynamiteProps {
  seconds?:number;
  run?: boolean;
  forceExplotion?: boolean;
  onFinish: () => void;
}

const TimerDynamite: React.FC<TymerDynamiteProps> = ({forceExplotion,onFinish}:TymerDynamiteProps) => {

  const [timerMinutes, setTimerMinutes] = React.useState<string>("3");
  const [seconds, setSeconds] = React.useState(180); 
  const [clock, setClock] = React.useState("00:00");
  const [percent, setPercent] = React.useState(0); 
  const [explodeAnimation, setExplodeAnimation] = React.useState(false); 

  const dispatch = useDispatch();
  let timerInterval: any;

  const isPlaying = useSelector((states: any) => {  
    return states.game.status === PLAYING
  }); 

  const isStarting = useSelector((states: any) => {  
    return states.game.status === STARTING
  });

  const isReady = useSelector((states: any) => {
    return states.game.status === READY
  });

  const isFinishedWon = useSelector((states: any) => {
    return states.game.status === FINISHED_WON;
  });

  const finishTimerDate = useSelector((states: any) => {
    return states.game.finishTimerDate || 0;
  });

  const totalTimerTimeSeconds = useSelector((states: any) => {
    return states.game.totalTimerTimeSeconds;
  });

  const dateStarted = useSelector((states: any) => {
    return states.game.dateStarted;
  });

  const fillerStyles = {
    height: 4,
    width: `${percent}%`,
    backgroundColor: "#1E2536",
    borderRadius: '0px 0px 0px 0px',
    transition: 'width 1s'
  } 

  const sparkStyle = {
    width: '30px',
    height: '30px',
    marginLeft: `${percent}%`,
    display: 'inline',
    zIndex: '8',
    marginTop:'-13px'
  }

  

  function updateClock(){    
    if(seconds > 0){
      setClock(Math.floor(seconds / 60).toString().padStart(2, "0")+":"+ Number(seconds - Math.floor(seconds / 60) * 60).toFixed(0).padStart(2, "0"));   
      setPercent(100 - Number(seconds)/totalTimerTimeSeconds*100);
    }   
  }  

  useEffect(() => {
    restartClock()
  }, [totalTimerTimeSeconds]);

  useEffect(() => {
    if(forceExplotion){
      timeout();
    }
  }, [forceExplotion]);

  React.useEffect(() => {
    if(isStarting || isPlaying) {
      Sounds.play('burnFuseSound',true); 
      timerInterval = Number(seconds) > 0 && setInterval(() =>{
        setSeconds(getSecondsLeft())        
      }, 300);
    }
    else{         
      clearInterval(timerInterval);
    }

    if(isReady || isFinishedWon){
      restartClock();  
      setSeconds(totalTimerTimeSeconds);
    }   

    return () =>{
      if(timerInterval) 
        clearInterval(timerInterval)
    };
  }, [isPlaying,isStarting,isReady,isFinishedWon]);

  useEffect(() => {
    if(isStarting || isPlaying) {
      if((new Date().getTime()) > finishTimerDate){
        timeout()
      }
    }
    updateClock();  

  },[seconds])

  function restartClock(){
    console.log("setting timer....")
    let timerValue = "3"
    if (window.location) {
      
      let param = new URLSearchParams(window.location.search).get(
        "timer"
      );  
      if (param)
        timerValue = param;
    }

    setSeconds(Number(timerValue)*60)
    dispatch(updateConfig({ timer: timerValue })); 
    updateClock(); 
    Sounds.stop('burnFuseSound');
    setExplodeAnimation(false);
  }

  function timeout(){
    Sounds.stop('burnFuseSound');	
    Sounds.play('explotion');	    
    setExplodeAnimation(true);  
    onFinish();
  }

  function getSecondsLeft(){
      return ((finishTimerDate - new Date().getTime())/1000);
  }

  return (
    <div className={styles.container}>
      
      <div className={styles.fuse}>

        <div className={styles.sparkContainer}>

        {isPlaying?<div style={sparkStyle}>
        <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 20.2815L12.3147 15.2067L8.29511 12.0778L13.3673 12.7078L12.1452 6.20494L15.8963 11.6788L20.4457 4L18.4183 12.7148L23.4975 12.1128L19.4638 15.2207L28.6545 19.5746L18.4112 17.7196L20.3115 25.3424L15.8822 18.7556L12.7244 22.7385L13.3602 17.7126L4 20.2815Z" fill="#FFD039" stroke="#EE733A" stroke-miterlimit="10"/>
        </svg>
        </div>:null}
      

        </div>
        <div style={fillerStyles}>
          
        </div>
      </div>
      <div className={styles.dynamite}>

        <div className={styles.explotion}>
          <ExplotionAnimation explode={explodeAnimation}/>
        </div>
        <div className={styles.clock}>
          {clock}
        </div>
       
      </div>
      
    </div>
  );
};

export default TimerDynamite;
