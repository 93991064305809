import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BoxStatus } from "../enums/boxStatus";
import { checkLetter } from "../helpers/checkLetter";


export function createText(solution:string,guessesWords:string[],seconds:number) {
        
  let text = "  Explodle  \n";
  for (let i = 0; i < guessesWords.length; i++){
      for (let j= 0; j < guessesWords[i].length; j++) {
          let status = checkLetter(solution,guessesWords[i],j) 
          switch(status) {
              case BoxStatus.correct:
                  text += "🟩";
                  break;
              case BoxStatus.absent:
                  text += "🟥";
                  break;
              case BoxStatus.present:
                  text += "🟨";
              break;
          }           
      }
      text += "\n";
  };
  text+= "\n Time:" + convertSecondsToClock(Math.abs(seconds)) +"\n";
  return text;    
}

function convertSecondsToClock(seconds:number){    
  return Math.floor(seconds / 60).toString().padStart(2, "0")+":"+ Number(seconds - Math.floor(seconds / 60) * 60).toFixed(0).padStart(2, "0");      
}

export const useGenerateShareText = () => {
    const [text, setText] = useState<string>("");
  
    const solution = useSelector((states: any) => {
      return states.game.wordToGuess;
    });
  
    const completedWords: string[] = useSelector((states: any) => {
      return states.game.completedWords || [];
    });
  
    const secondsConsumed = useSelector((states: any) => {
      return states.game.secondsConsumed;
    });
  
    useEffect(() => {    
      setText(createText(solution,completedWords,secondsConsumed))    
    }, [completedWords])
  
  
    useEffect(() => {    
      console.log(text)
    }, [text])
  
    return {text}
  
  }
  