import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import styles from "./modalRegister.module.scss";
import  FBAuth  from '../../services/firebase/auth';
import { closeOutline} from 'ionicons/icons';
import { Link } from 'react-router-dom';

interface ModalRegisterProps{
    show: boolean;
    onClose: () => void;
    onRegister: () => void;
    onLogin: () => void;
}

const ModalRegister: React.FC<ModalRegisterProps> = ({show,onClose,onLogin,onRegister}:ModalRegisterProps) => {   

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const [errorInfo, setErrorInfo] = useState({show:false,msg:""}); 

    const register = () => {
        
        FBAuth.registerWithEmailAndPassword(name, email, password).then((data) => {
            if(data)
                setErrorInfo({show:true,msg:data});
        }).catch(error => {
            setErrorInfo({show:true,msg:error});
        });
    };

    function dismiss() {        
        onClose();
    }
    
    return (
        <>      
        <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
            <IonToolbar>
                <IonTitle className={styles.modalTitle}>Register</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="light" onClick={() => dismiss()}>
                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className={styles.content}>
            <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                    type="text"
                    onIonChange={(e:any) => {
                    setName(e.detail.value);
                    }}
                    name="name"
                />
                </IonItem>
                <IonItem>
                <IonLabel position="floating">Email Address</IonLabel>
                <IonInput
                    type="email"
                    onIonChange={(e:any) => {
                    setEmail(e.detail.value);
                    }}
                    name="email"
                />
                </IonItem>
                <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                    type="password"
                    onIonChange={(e:any) => {
                    setPassword(e.detail.value);
                    }}
                    name="password"
                />
                </IonItem>
                <div style={{ padding: 10, paddingTop: 20 }}>
                <IonButton
                    expand="full"
                    style={{ margin: 14 }}
                    onClick={()=>{
                        register()
                    }}
                >Register
                </IonButton>
                <IonButton
                    expand="full"
                    style={{ margin: 14 }}
                    onClick={()=>{
                        FBAuth.signInWithGoogle().then((data)=>{
                            onClose();
                        }).catch((error)=>{
                        setErrorInfo({show:true,msg:error})
                        })
                        
                    }}
                >
                    Register with Google
                </IonButton>
                <div onClick={()=>{onLogin()}}>
                    Already have an account? <Link to="">Login</Link> now.
                </div>
                </div>
            </IonContent>
        </IonModal> 
        <IonToast
              color="danger"
              isOpen={errorInfo.show}
              onDidDismiss={() => setErrorInfo({show:false,msg:""})}
              message={errorInfo.msg}
              duration={2000}
            />           
    </>
    );
    
};

export default ModalRegister;