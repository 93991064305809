export const WORDS_SPANISH=[
    "aboya",
    "aboyá",
    "abran",
    "abras",
    "abrás",
    "abría",
    "acles",
    "acoja",
    "acojo",
    "acres",
    "actas",
    "actos",
    "actúo",
    "acuna",
    "acune",
    "acuno",
    "acuná",
    "acuné",
    "acunó",
    "acuso",
    "acusó",
    "acuña",
    "acuñe",
    "acuño",
    "acuñá",
    "acuñé",
    "acuñó",
    "afeas",
    "afeás",
    "aguda",
    "agudo",
    "alajú",
    "albas",
    "albos",
    "alces",
    "alcés",
    "algas",
    "alhaj",
    "aliás",
    "almas",
    "alojo",
    "alojá",
    "alojó",
    "altas",
    "altos",
    "alías",
    "amina",
    "aminá",
    "ancas",
    "andas",
    "andes",
    "andés",
    "anima",
    "animá",
    "aojar",
    "aojas",
    "aojos",
    "aojás",
    "apoda",
    "apodo",
    "apodá",
    "apodó",
    "aptas",
    "aptás",
    "apuré",
    "arces",
    "arcos",
    "ardan",
    "ardas",
    "ardes",
    "ardás",
    "ardés",
    "arias",
    "arios",
    "arlos",
    "armes",
    "armés",
    "arpas",
    "artes",
    "Aruba",
    "aruñe",
    "aruño",
    "aruñé",
    "aruñó",
    "aréis",
    "ascos",
    "asees",
    "aseos",
    "asiló",
    "asiré",
    "asolo",
    "aséis",
    "asían",
    "asías",
    "atoja",
    "atojo",
    "atojá",
    "atojó",
    "avale",
    "avara",
    "azoté",
    "aérea",
    "bacán",
    "bagas",
    "bagás",
    "bajas",
    "bajás",
    "balas",
    "bales",
    "balás",
    "balés",
    "bares",
    "barre",
    "barrá",
    "barré",
    "basca",
    "bascá",
    "baste",
    "bastá",
    "basté",
    "batas",
    "bates",
    "bateá",
    "batás",
    "batís",
    "bayas",
    "bañas",
    "bañes",
    "Baños",
    "baños",
    "bañés",
    "bebed",
    "bebes",
    "bebés",
    "besen",
    "beses",
    "besos",
    "besés",
    "betas",
    "bodas",
    "bogad",
    "bogas",
    "bogue",
    "bogué",
    "bogás",
    "bojar",
    "bojas",
    "bojos",
    "bojás",
    "bolar",
    "bollá",
    "bondi",
    "bonos",
    "borre",
    "borré",
    "boses",
    "bosés",
    "botad",
    "botan",
    "botas",
    "boten",
    "botes",
    "botás",
    "botés",
    "boyas",
    "boyás",
    "bramo",
    "bramó",
    "Bruno",
    "bruta",
    "bruño",
    "bruñó",
    "bríos",
    "bulas",
    "bulos",
    "buscá",
    "buscó",
    "buses",
    "caceo",
    "cachó",
    "cafés",
    "cagas",
    "cagás",
    "cajas",
    "calce",
    "calcé",
    "calcó",
    "cales",
    "callo",
    "calló",
    "calme",
    "calmo",
    "calmé",
    "calmó",
    "calés",
    "campó",
    "canas",
    "canes",
    "cansa",
    "cansá",
    "canta",
    "cantó",
    "capas",
    "capes",
    "cappa",
    "capta",
    "capto",
    "captá",
    "captó",
    "capás",
    "capés",
    "cargá",
    "cargó",
    "Carla",
    "caros",
    "casan",
    "casas",
    "casca",
    "cascá",
    "cascó",
    "casos",
    "casás",
    "Catar",
    "catas",
    "catás",
    "cause",
    "causé",
    "cavas",
    "cavos",
    "cazan",
    "cazas",
    "cazos",
    "caída",
    "caído",
    "cañas",
    "cañeo",
    "cañás",
    "cañís",
    "cebos",
    "cedas",
    "ceded",
    "cedes",
    "cedás",
    "cedés",
    "cefos",
    "cejas",
    "cejás",
    "celes",
    "cenas",
    "cepas",
    "ceras",
    "cercó",
    "cerdo",
    "ceros",
    "cerrá",
    "cerró",
    "chefs",
    "Chile",
    "China",
    "chiís",
    "chocó",
    "chuzá",
    "ciega",
    "cines",
    "clamo",
    "clamó",
    "Clara",
    "clava",
    "clavá",
    "clavé",
    "clavó",
    "clubs",
    "coces",
    "cocés",
    "codeo",
    "coged",
    "cogen",
    "coger",
    "coges",
    "cogés",
    "cogía",
    "cojas",
    "cojos",
    "cojás",
    "colas",
    "coles",
    "colma",
    "colmá",
    "colás",
    "colón",
    "coman",
    "comas",
    "combó",
    "comed",
    "comes",
    "comás",
    "comés",
    "conos",
    "copas",
    "copos",
    "copta",
    "copás",
    "coral",
    "coras",
    "Corea",
    "corea",
    "coreá",
    "corra",
    "corre",
    "corro",
    "corré",
    "corrí",
    "corsa",
    "cosas",
    "coses",
    "costó",
    "cosás",
    "cosés",
    "coños",
    "creas",
    "crees",
    "creme",
    "cremo",
    "cremé",
    "cremó",
    "creés",
    "croar",
    "cruje",
    "críen",
    "cubas",
    "cubos",
    "cucos",
    "cuida",
    "cuide",
    "cuidá",
    "cuidé",
    "culos",
    "culta",
    "cunas",
    "cunás",
    "cural",
    "curas",
    "curdo",
    "cures",
    "curio",
    "cursó",
    "curás",
    "curés",
    "cuñas",
    "cuñás",
    "dagas",
    "datos",
    "dañes",
    "daños",
    "dañés",
    "debas",
    "debed",
    "debes",
    "debés",
    "dedos",
    "dejas",
    "dejás",
    "densa",
    "dices",
    "dijes",
    "diles",
    "doman",
    "domas",
    "domes",
    "domos",
    "domás",
    "domés",
    "donan",
    "donas",
    "donen",
    "dones",
    "donás",
    "donés",
    "dores",
    "doria",
    "dorio",
    "dotas",
    "dotes",
    "dotás",
    "dotés",
    "doñas",
    "drupa",
    "Dubai",
    "Dubái",
    "dudan",
    "dunas",
    "dures",
    "duros",
    "durés",
    "dúhos",
    "echas",
    "eches",
    "echos",
    "echés",
    "edita",
    "editá",
    "eleve",
    "elevé",
    "ellos",
    "emana",
    "emaná",
    "emoji",
    "emoyi",
    "emula",
    "emule",
    "emulo",
    "emulá",
    "emulé",
    "emuló",
    "emúes",
    "enoja",
    "enojo",
    "enojá",
    "entes",
    "envío",
    "erizo",
    "errar",
    "erres",
    "errés",
    "espiá",
    "estad",
    "estas",
    "euros",
    "evita",
    "evitá",
    "falle",
    "fallé",
    "falos",
    "faltá",
    "ferio",
    "fetos",
    "ficad",
    "ficas",
    "ficás",
    "fijas",
    "fijás",
    "filas",
    "files",
    "filás",
    "fines",
    "fique",
    "fiqué",
    "firmé",
    "focos",
    "folia",
    "foliá",
    "folla",
    "follo",
    "folló",
    "fomes",
    "forcé",
    "forme",
    "formé",
    "fosos",
    "fotos",
    "frita",
    "fritá",
    "frías",
    "fugas",
    "fugás",
    "fumes",
    "fumés",
    "Gabón",
    "gafas",
    "galas",
    "Gales",
    "galos",
    "ganes",
    "ganás",
    "ganés",
    "gases",
    "gastó",
    "gatos",
    "genes",
    "gimen",
    "gimes",
    "giras",
    "giros",
    "girás",
    "godas",
    "godos",
    "goles",
    "gorda",
    "gorra",
    "grabe",
    "grabé",
    "grave",
    "gravé",
    "grite",
    "gritá",
    "grité",
    "gritó",
    "groar",
    "grúas",
    "gurús",
    "güera",
    "habas",
    "habed",
    "habla",
    "hablá",
    "hablá",
    "habrá",
    "habré",
    "había",
    "hacen",
    "haces",
    "hacés",
    "hacía",
    "hagan",
    "hagas",
    "hagás",
    "Haití",
    "halan",
    "halas",
    "hallo",
    "hallá",
    "halos",
    "halás",
    "harte",
    "harán",
    "harás",
    "haría",
    "hastí",
    "hayan",
    "hayas",
    "hayás",
    "heces",
    "hecha",
    "hecho",
    "hemos",
    "hiero",
    "hijas",
    "hilan",
    "hilos",
    "hinca",
    "hipos",
    "hitos",
    "hoces",
    "hocés",
    "hojas",
    "huera",
    "huero",
    "hunas",
    "hunos",
    "hurgo",
    "husos",
    "ibais",
    "ideas",
    "ideás",
    "iglús",
    "India",
    "infle",
    "inflé",
    "intis",
    "iréis",
    "irían",
    "irías",
    "isbas",
    "islas",
    "Ivana",
    "jalan",
    "jalón",
    "Japón",
    "jefes",
    "jemal",
    "jemes",
    "jerga",
    "jodas",
    "jodes",
    "jodás",
    "jodés",
    "jonia",
    "joyas",
    "joyel",
    "jugos",
    "jujeo",
    "jures",
    "jurés",
    "kanes",
    "Kenia",
    "kunas",
    "kurda",
    "labes",
    "labia",
    "labre",
    "labré",
    "lacra",
    "lacta",
    "lactá",
    "lados",
    "ladro",
    "ladró",
    "Lagos",
    "lagos",
    "lamas",
    "lamen",
    "lames",
    "lamás",
    "lamés",
    "lapas",
    "larga",
    "latas",
    "latás",
    "laves",
    "lavés",
    "laxas",
    "laxos",
    "laxás",
    "lazan",
    "lazos",
    "legas",
    "legos",
    "lejos",
    "lemas",
    "lenta",
    "levas",
    "leves",
    "levás",
    "levés",
    "leyes",
    "leías",
    "Libia",
    "libia",
    "libré",
    "libró",
    "lides",
    "limas",
    "linda",
    "liras",
    "lises",
    "locas",
    "locha",
    "locos",
    "lomos",
    "lores",
    "loros",
    "losas",
    "losás",
    "loteo",
    "lotes",
    "lozas",
    "luces",
    "luche",
    "lucho",
    "luchá",
    "luché",
    "luchó",
    "lunas",
    "lusas",
    "lusos",
    "maceo",
    "magos",
    "Maine",
    "malas",
    "males",
    "malos",
    "Malta",
    "malís",
    "mamas",
    "mamás",
    "manas",
    "manca",
    "mancá",
    "mancó",
    "manda",
    "mandó",
    "mangó",
    "manos",
    "manás",
    "mapas",
    "marcó",
    "mares",
    "marro",
    "Marín",
    "masco",
    "mascó",
    "mases",
    "masés",
    "matas",
    "mates",
    "matás",
    "matés",
    "mayas",
    "mayás",
    "mazos",
    "mañas",
    "meaba",
    "mecen",
    "meces",
    "mecés",
    "mediá",
    "memes",
    "menes",
    "mentá",
    "menés",
    "menús",
    "meras",
    "meros",
    "merás",
    "mesas",
    "mesen",
    "meses",
    "mesás",
    "mesés",
    "metas",
    "metes",
    "metás",
    "mezas",
    "mezás",
    "midas",
    "midás",
    "migro",
    "miles",
    "minas",
    "mines",
    "minás",
    "minés",
    "miras",
    "miren",
    "mires",
    "mirás",
    "mirés",
    "misad",
    "misas",
    "misma",
    "mista",
    "mistá",
    "misás",
    "mitos",
    "mitro",
    "mitró",
    "mocos",
    "modas",
    "modos",
    "mofes",
    "mofés",
    "mojar",
    "mojas",
    "mojen",
    "mojos",
    "mojás",
    "molas",
    "moles",
    "molás",
    "molés",
    "monas",
    "monos",
    "monté",
    "montó",
    "mopas",
    "moras",
    "mores",
    "moros",
    "morro",
    "morás",
    "morés",
    "Moscú",
    "motas",
    "moved",
    "mozas",
    "moños",
    "mucas",
    "mucos",
    "mudas",
    "mudos",
    "mudás",
    "mueve",
    "mugen",
    "muges",
    "mugid",
    "mugió",
    "mugís",
    "mujan",
    "mujas",
    "mulas",
    "multe",
    "multá",
    "multé",
    "muros",
    "musás",
    "nabos",
    "naces",
    "nacés",
    "nadas",
    "nades",
    "nadés",
    "narre",
    "narré",
    "natas",
    "natos",
    "Nauru",
    "Naurú",
    "naves",
    "nazca",
    "nazis",
    "necia",
    "Nepal",
    "netos",
    "niños",
    "nomos",
    "notas",
    "notes",
    "notás",
    "notés",
    "nubes",
    "nucas",
    "nudas",
    "nudos",
    "nulas",
    "nulos",
    "Níger",
    "obras",
    "odias",
    "odios",
    "odiás",
    "ogros",
    "ollas",
    "omaní",
    "ombús",
    "ondas",
    "onzas",
    "opera",
    "operá",
    "optas",
    "optás",
    "orbes",
    "orcas",
    "orden",
    "ornan",
    "oseas",
    "oseás",
    "osito",
    "otras",
    "ovulo",
    "ovuló",
    "oírte",
    "pacas",
    "pacen",
    "pacta",
    "pactá",
    "pactó",
    "pacés",
    "pagan",
    "pague",
    "pagué",
    "pajas",
    "pajes",
    "palas",
    "palme",
    "palmá",
    "palmé",
    "palos",
    "palpa",
    "panes",
    "papúa",
    "papús",
    "paras",
    "parda",
    "Pardo",
    "paree",
    "paren",
    "pares",
    "pareé",
    "parta",
    "partí",
    "parás",
    "paría",
    "París",
    "parís",
    "pasas",
    "pasen",
    "pases",
    "paseó",
    "pasás",
    "pasés",
    "patas",
    "patea",
    "pateá",
    "patos",
    "patés",
    "pañal",
    "pecas",
    "peces",
    "pedos",
    "pegas",
    "pegue",
    "pelos",
    "penan",
    "penas",
    "penda",
    "pende",
    "penen",
    "penes",
    "pensá",
    "pensé",
    "penás",
    "penés",
    "peras",
    "perdí",
    "peros",
    "pesad",
    "pesas",
    "pescá",
    "pesen",
    "peses",
    "pesos",
    "pesás",
    "pesés",
    "petas",
    "petás",
    "peáis",
    "peñas",
    "pican",
    "pidas",
    "pides",
    "pidás",
    "pines",
    "pipas",
    "pisad",
    "pises",
    "pitos",
    "plant",
    "plena",
    "pleno",
    "pobló",
    "pocas",
    "pocos",
    "podas",
    "poded",
    "poder",
    "podes",
    "podrá",
    "podré",
    "podái",
    "podás",
    "podés",
    "podía",
    "poned",
    "ponen",
    "poner",
    "pones",
    "ponga",
    "ponés",
    "ponía",
    "porfa",
    "posas",
    "posee",
    "posen",
    "poses",
    "poseé",
    "posos",
    "posás",
    "posés",
    "potos",
    "poyas",
    "poyás",
    "pozos",
    "Prada",
    "prado",
    "preña",
    "preño",
    "preñá",
    "preñó",
    "probó",
    "pueda",
    "puede",
    "puedo",
    "pujan",
    "pujas",
    "pujen",
    "pujás",
    "pulen",
    "pumas",
    "puras",
    "purgá",
    "puros",
    "putas",
    "Qatar",
    "quede",
    "quedé",
    "queme",
    "quemo",
    "quemá",
    "quemé",
    "quemó",
    "queré",
    "quise",
    "quiso",
    "Quito",
    "quito",
    "quitó",
    "rabiá",
    "rabos",
    "rabón",
    "ralbe",
    "ralbé",
    "ramos",
    "ranas",
    "rapas",
    "rapás",
    "rasas",
    "rasco",
    "rascá",
    "raspá",
    "rasás",
    "ratas",
    "ratos",
    "razas",
    "razás",
    "raéis",
    "reces",
    "recia",
    "recés",
    "redes",
    "regia",
    "regía",
    "reinó",
    "rejas",
    "remas",
    "remes",
    "remos",
    "remás",
    "remés",
    "rento",
    "rentá",
    "rentó",
    "reses",
    "reste",
    "resté",
    "restó",
    "retes",
    "retos",
    "retés",
    "reyes",
    "rezan",
    "rezas",
    "rezás",
    "reúna",
    "rices",
    "rifas",
    "rimas",
    "rimen",
    "rimes",
    "rimás",
    "rimés",
    "rinda",
    "ritos",
    "roban",
    "robes",
    "robés",
    "rocas",
    "rodas",
    "rodás",
    "rojas",
    "rojos",
    "rolla",
    "rollá",
    "rolló",
    "romas",
    "romos",
    "rompe",
    "rompé",
    "rones",
    "rotas",
    "rotos",
    "rotás",
    "royas",
    "royos",
    "royás",
    "rubís",
    "rudos",
    "rugen",
    "ruges",
    "rugió",
    "rugís",
    "rujan",
    "rulos",
    "runas",
    "rusas",
    "Rusia",
    "rusos",
    "ruñas",
    "ruñás", 
    "sabia",
    "sabía",
    "sacan",
    "sacas",
    "sacos",
    "sacás",
    "sajón",
    "salga",
    "salgo",
    "salgá",
    "salgó",
    "salta",
    "saltá",
    "saltó",
    "salva",
    "salve",
    "salvá",
    "salvé",
    "salvó",
    "salís",
    "Samoa",
    "sanad",
    "sanas",
    "santa",
    "sanás",
    "sapos",
    "saudí",
    "sañas",
    "sebos",
    "secan",
    "sedes",
    "sedés",
    "sentá",
    "senté",
    "sentó",
    "sepas",
    "seres",
    "seria",
    "seriá",
    "serró",
    "serán",
    "serás",
    "sería",
    "setas",
    "señás",
    "siega",
    "siego",
    "sigan",
    "Siria",
    "siria",
    "siseo",
    "sitúo",
    "soban",
    "sobas",
    "sobes",
    "sobés",
    "sogas",
    "soles",
    "solés",
    "solía",
    "sonad",
    "sonar",
    "sondá",
    "sonás",
    "sopas",
    "sopás",
    "sorbe",
    "sorbé",
    "soñad",
    "soñar",
    "soñás",
    "soñés",
    "subas",
    "subes",
    "sucia",
    "sucre",
    "sudan",
    "sudar",
    "sudas",
    "Sudán",
    "sudás",
    "suela",
    "suele",
    "suena",
    "suene",
    "sueno",
    "sueña",
    "sueñe",
    "sueño",
    "Suiza",
    "suiza",
    "sumas",
    "sumás",
    "supón",
    "sushi",
    "sáxeo",
    "súper",
    "tabús",
    "tacos",
    "talas",
    "talás",
    "talés",
    "tapas",
    "tapes",
    "tapás",
    "tapés",
    "tardé",
    "Tejas",
    "tejas",
    "temas",
    "temen",
    "temes",
    "temás",
    "temés",
    "tened",
    "tenga",
    "tengo",
    "tenia",
    "tensa",
    "tensá",
    "tensó",
    "tenés",
    "tenía",
    "terca",
    "tersa",
    "terse",
    "tersá",
    "tersé",
    "tersó",
    "testo",
    "testá",
    "testó",
    "tetar",
    "tetas",
    "tetás",
    "Texas",
    "teñía",
    "tiene",
    "times",
    "timés",
    "tipos",
    "tiras",
    "tiros",
    "tirás",
    "tisús",
    "titán",
    "tocás",
    "togas",
    "togás",
    "tomad",
    "toman",
    "tomas",
    "tomes",
    "tomás",
    "tomés",
    "Tonga",
    "tonos",
    "tonta",
    "topas",
    "topos",
    "topás",
    "toqué",
    "torpe",
    "tosed",
    "toses",
    "traed",
    "traen",
    "traer",
    "traes",
    "tragá",
    "trajo",
    "tramá",
    "trató",
    "traés",
    "traía",
    "trinó",
    "trocó",
    "tronó",
    "troté",
    "tríos",
    "tubos",
    "tumbá",
    "tunas",
    "tunás",
    "tólar",
    "Túnez", 
    "untes",
    "unías",
    "urdas",
    "Uribe",
    "urnas",
    "usaos",
    "usate",
    "uséis",
    "uñoso", 
    "vacan",
    "vacas",
    "vagas",
    "vagos",
    "vague",
    "vagué",
    "vagás",
    "vales",
    "valga",
    "valgo",
    "valsa",
    "valse",
    "valsá",
    "valsé",
    "valés",
    "vamos",
    "vasca",
    "vasos",
    "vasta",
    "vates",
    "vayan",
    "vayas",
    "vayás",
    "veces",
    "vecés",
    "vedas",
    "velas",
    "veles",
    "velos",
    "velás",
    "velés",
    "vemos",
    "venas",
    "vendé",
    "vengo",
    "vengó",
    "vente",
    "venté",
    "verás",
    "veréi", 
    "vería",
    "vetas",
    "veían",
    "veías",
    "vidas",
    "viene",
    "viera",
    "vigas",
    "vinos",
    "vires",
    "vivás",
    "voces",
    "volar",
    "volví",
    "volás",
    "votad",
    "votan",
    "votar",
    "votas",
    "voten",
    "votes",
    "votos",
    "votás",
    "votés",
    "vudús",
    "vídeo",
    "wikis",
    "wones",
    "yates",
    "yemas",
    "Yemen",
    "yendo",
    "yenes",
    "yesca",
    "yogur",
    "yugos", 
    "zacas",
    "zarca",
    "zares",
    "zetas",
    "zonas",
    "zorro",
    "zulús",
    "zurda",
    "zurro",
]