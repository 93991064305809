import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { auth } from '../services/firebase/config';

const ResetPassword: React.FC = () => {

const [email, setEmail] = useState("");
const [user, loading, error] = useAuthState(auth);
const history = useHistory();

useEffect(() => {
    if (loading) return;
    if (user) history.replace("/");
}, [user, loading]);
 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>         
          <IonTitle class="ion-text-center">
          Explodle
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
       
        <IonItem>
          <IonLabel position="floating">Email Address</IonLabel>
          <IonInput
            type="email"
            onIonChange={(e:any) => {
              setEmail(e.detail.value);
            }}
            name="email"
          />
        </IonItem>
        
        <div style={{ padding: 10, paddingTop: 20 }}>
          <IonButton
            expand="full"
            style={{ margin: 14 }}
            onClick={() => sendPasswordResetEmail(auth,email)}
          >Send password reset email
          </IonButton>
          
          <div>
          Don't have an account? <Link to="/register">Register</Link> now.
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
