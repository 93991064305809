import { useSelector } from "react-redux";
import { BoxStatus } from "../../enums/boxStatus";
import { checkLetter } from "../../helpers/checkLetter";
import Box from "../Box/Box";
import BoxCompleted from "../BoxCompleted/BoxCompleted";
import styles from "../row.module.scss";

interface RowCompletedProps {
  word: string;
  animate: boolean;
}

const RowCompleted: React.FC<RowCompletedProps> = ({
  word,
  animate = false,
}: RowCompletedProps) => {
  const solution = useSelector((states: any) => {
    return states.game.wordToGuess;
  });

  const arr = Array.from(Array(5));

  return (
    <div className={styles.row}>
      {arr.map((_, i) => (
        <BoxCompleted
          key={i}
          value={word[i]}
          status={checkLetter(solution, word, i)}
          animate={animate}
          pos={i}
        />
      ))}
    </div>
  );
};

export default RowCompleted;
