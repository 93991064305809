import { Share } from "@capacitor/share";
import {
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGenerateShareText } from "../../hooks/useGenerateText";
import { FINISHED_LOST } from "../../redux/constants/action-types";
import RowCompleted from "../RowCompleted/RowCompleted";
import RowCurrent from "../RowCurrent/RowCurrent";
import UserStatistics from "../UserStatistics/UserStatistics";
import styles from "./modalLost.module.scss";

interface ModalLostProps {
  show: boolean;
  onClose: () => void;
  onShareResults: () => void;
}

const ModalLost: React.FC<ModalLostProps> = ({
  show,
  onClose,
  onShareResults,
}: ModalLostProps) => {
  const solution = useSelector((states: any) => {
    return states.game.wordToGuess;
  });

  function dismiss() {
    onClose();
  }

  const { text } = useGenerateShareText();

  async function share() {
    console.log(text);
    await Share.share({
      title: "Look at this!",
      text: text,
      url: "http://explodle.com/",
      dialogTitle: "Share my new achievement",
    });
  }

  return (
    <>
      <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol></IonCol>
                <IonCol className={styles.modalTitle}>
                  <IonTitle>
                    <IonTitle className={styles.modalTitle}>
                      &#128577;{" "}
                    </IonTitle>
                  </IonTitle>
                </IonCol>
                <IonCol>
                  <IonButtons>
                    <IonButton
                      className={styles.closeButton}
                      onClick={() => dismiss()}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className={styles.content}>
          <p className={styles.contentTitle}>Next Time!</p>

          <RowCurrent key={1} word={solution} />
          <UserStatistics />
          <IonButton
            className="share-button"
            color="primary"
            onClick={() => share()}
          >
            Share Results
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalLost;
