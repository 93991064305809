import { WORDS_SPANISH } from "./words-spanish";
import { ALLOWED_WORDS_ENGLISH } from "./allowed-words-english";
import { ANSWER_WORDS_ENGLISH } from "./answer-words-english";
export enum Languages {
  spanish = "spanish",
  english = "english"
}

class Dictionary { 

  async isValidWord(word: string, language:Languages) {     
    if(language === Languages.spanish){
      const words = this.filterSpanishWords();
      return words.includes(word.toLowerCase());
    }

    if(language === Languages.english){
      return ALLOWED_WORDS_ENGLISH.includes(word.toLowerCase());
    }       
  }

  async  getRandomWordToGuess(language:Languages) {
    let getWordToGuess =  "";

    switch(language) {
      case Languages.spanish:
        let words = this.filterSpanishWords();
        getWordToGuess = words[Math.floor(Math.random() * words.length)];        
      break;
      case Languages.english:
        getWordToGuess = ANSWER_WORDS_ENGLISH[Math.floor(Math.random() * ANSWER_WORDS_ENGLISH.length)];        
      break;
    }
    return getWordToGuess.toUpperCase();
    
  }

  filterSpanishWords():string[] {
    let w = WORDS_SPANISH.filter(word => (!/^[ÿ\u00f1\u00d1\u00C0-\u017F]+$/.test(word)));
    return w;
  }

  getWordOfTheDay(language:Languages) {
    const day = this.getDayOfTheYear();
    
    if(language == Languages.spanish){
      let words = this.filterSpanishWords();
      return words[day % words.length];     
    }
    
    if(language == Languages.english){
      return ANSWER_WORDS_ENGLISH[day % ANSWER_WORDS_ENGLISH.length];
    }    
  }

  getDayOfTheYear() {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff =
      (now as any) -
      (start as any) +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  }

}

const _Dictionary = new Dictionary();
export default _Dictionary;
  