import React, { useEffect, useState } from 'react'; 
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import styles from './userStatistics.module.scss';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDocument } from 'react-firebase-hooks/firestore';

import {  doc } from 'firebase/firestore';
import {  auth, db } from "../../services/firebase/config";
import { IonGrid, IonRow } from '@ionic/react';
import StatisticsPieChart from '../StatisticsPieChart/StatisticsPieChat';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);

const UserStatistics: React.FC = () => {

    const storedUserId:string = useSelector((states: any) => {
        return states.game.userId || '';
      });
    const [userData] = useDocument(doc(db, "users", storedUserId || "default" ),{});
    const [statistics,setStatistics] = useState<any>({
        totalWins:0,
        totalLosses:0,
        inOneTurn:0,
        inTwoTurns:0,
        inThreeTurns:0,
        inFourTurns:0,
        inFiveTurns:0,
        inSixTurns:0,
    });

    useEffect(() => { 
        if(userData?.data())
            setStatistics(userData?.data()?.statistics);
    },[userData,storedUserId])  
   

    return( 
        <>
            <h5 className={styles.subtitle}>Your stats</h5 >
                <hr />
            <IonGrid className={styles.statisticsRow}>
                <IonRow>
                    <div className={styles.column}>
                        <div className={styles.statisticsNumber}>{statistics?.totalWins + statistics?.totalLosses}</div>
                        <div className={styles.statisticsLabel}>Played</div>    
                    </div>
                    <div className={styles.column}>
                        <div className={styles.statisticsNumber}>{statistics.currentStreak}</div>
                        <div className={styles.statisticsLabel}>Current Streak</div>    
                    </div>
                    <div className={styles.column}>
                        <div className={styles.statisticsNumber}>{
                        (statistics?.totalWins+statistics?.totalLosses)?                        
                        Number(statistics?.totalWins*100/(statistics?.totalWins+statistics?.totalLosses)).toFixed(0):
                        "-"
                        }</div>
                        <div className={styles.statisticsLabel}>Win%</div>    
                    </div>
                    <div className={styles.column}>
                        <div className={styles.statisticsNumber}>{statistics.bestStreak}</div>
                        <div className={styles.statisticsLabel}>Max Streak</div>    
                    </div>
                </IonRow>
            </IonGrid>
            <hr />
            <h5 className={styles.subtitle}>Correct guess in</h5 >


            <div className={styles.pieContainer}>
                <StatisticsPieChart uid={storedUserId}/>
            </div>
        </>
            
    )

}

export default UserStatistics;