import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BoxStatus } from "../../enums/boxStatus";
import Box from "../Box/Box";
import styles from "./modalTutorial.module.scss";

interface ModalTutorialProps {
  show: boolean;
  onClose: () => void;
}

const ModalTutorial: React.FC<ModalTutorialProps> = ({
  show,
  onClose,
}: ModalTutorialProps) => {
  function dismiss() {
    onClose();
  }

  return (
    <>
      <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
      <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol></IonCol>
                <IonCol className={styles.modalTitle}>
                  <IonTitle >
                    HOW TO PLAY
                  </IonTitle>
                </IonCol>
                <IonCol>
                  <IonButtons>
                    <IonButton className={styles.closeButton}  onClick={() => dismiss()}>
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className={styles.content}>
         
          <p>
            Guess the WORD in six tries{" "}
            <span className={styles.redText}>
              before time runs out and you explode!
            </span>{" "}
          </p>
          <p>Each guess must be a valid five-letter word.</p>
          <p>Hit the enter button to submit.</p>
          <p>
            After each guess, the color of the tiles will change to show how
            close your guess was to the word.
          </p>
          <div className={styles.row}>
            <Box value="W" status={BoxStatus.correct} animate={true} />
            <Box value="E" status={BoxStatus.empty} animate={true} />
            <Box value="D" status={BoxStatus.empty} animate={true} />
            <Box value="G" status={BoxStatus.empty} animate={true} />
            <Box value="E" status={BoxStatus.empty} animate={true} />
          </div>
          <p>The letter W is in the word and in the correct spot.</p>
          <div className={styles.row}>
            <Box value="H" status={BoxStatus.empty} animate={true} />
            <Box value="I" status={BoxStatus.present} animate={true} />
            <Box value="L" status={BoxStatus.empty} animate={true} />
            <Box value="L" status={BoxStatus.empty} animate={true} />
            <Box value="S" status={BoxStatus.empty} animate={true} />
          </div>

          <p>The letter I is in the word but in the wrong spot.</p>

          <div className={styles.row}>
            <Box value="V" status={BoxStatus.empty} animate={true} />
            <Box value="A" status={BoxStatus.empty} animate={true} />
            <Box value="U" status={BoxStatus.absent} animate={true} />
            <Box value="L" status={BoxStatus.empty} animate={true} />
            <Box value="T" status={BoxStatus.empty} animate={true} />
          </div>
          <p>The letter U is not in the word in any spot</p>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalTutorial;
