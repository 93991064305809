

import { query, collection, where, getDocs, addDoc, Timestamp, updateDoc, doc, setDoc } from 'firebase/firestore';
import {  db } from "./config";

class FBUser {

  async fetchUserName(user:any){
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      return data.name;
    } catch (err) {
      console.error(err);
      return "";
    }
};

async startNewGame(userId:string,gameId:string){
 setDoc(doc(db, "users", userId ,"games",gameId), {
    
    timerMinutes:0,
    dateStarted:Timestamp.now(),
    wordToGuess:"",
    currentWord:"",
    turn:1,
    completedWords:[],
    finish:false,
    result:"",
    isWon:false,
    userId:userId,
  });
}

updateGame(game:any){
  console.log(game);
  setDoc(doc(db, "users", game.userId ,"games", game.gameId),{
    timerMinutes:game.timerMinutes,
    secondsLeft:game.secondsLeft,
    dateFinished:Timestamp.now(),
    wordToGuess:game.wordToGuess,
    currentWord:game.currentWord,
    turn:game.turn,
    completedWords:game.completedWords,
    finish:game.finish,
    isWon:game.isWon,
  }, { merge: true });
  if(game.finish)
    this.updateFinishStatistics(game);
}

async updateFinishStatistics(game:any){

  const q = query(collection(db, "users"), where("uid", "==", game.userId));
  const docData = await getDocs(q);
  
  const data = docData.docs[0].data();

  let newBestStreak:number = 0;
  let currentStreak:number = 0;
  
  if(game.isWon){
    if(!data.statistics.currentStreak)
      data.statistics.currentStreak = 0;
  
    if(!data.statistics.bestStreak)
      data.statistics.bestStreak = 0;
    
    currentStreak = data.statistics.currentStreak + 1;

    if(data.statistics.bestStreak < currentStreak){
      newBestStreak = currentStreak;
    }
    else{
      newBestStreak = data.statistics.bestStreak;
    }     
  }
  else{
    currentStreak = 0
  }
  console.log(game)
  await updateDoc(doc(db, "users",game.userId), {
    statistics:{   
      dateFinisished:Timestamp.now(),
      bestStreak:newBestStreak,
      currentStreak:currentStreak,
      totalWins:data.statistics.totalWins + (game.isWon === true),
      totalLosses:data.statistics.totalLosses + (game.isWon === false),        
      inOneTurn:data.statistics.inOneTurn + (game.turn === 1 && game.isWon === true),
      inTwoTurns:data.statistics.inTwoTurns + (game.turn === 2 && game.isWon === true),
      inThreeTurns:data.statistics.inThreeTurns + (game.turn === 3 && game.isWon === true),
      inFourTurns:data.statistics.inFourTurns + (game.turn === 4 && game.isWon === true),
      inFiveTurns:data.statistics.inFiveTurns + (game.turn === 5 && game.isWon === true),
      inSixTurns:data.statistics.inSixTurns + (game.turn === 6 && game.isWon === true),
    }
  });
}

async checkIfUserExists(userId:string){
  try {
    const q = query(collection(db, "users"), where("uid", "==", userId));
    const doc = await getDocs(q);
    if(doc.docs[0]){
      return true;
    }
    else{
      return false
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

generateStatistics(userId:string){
  setDoc(doc(db, "users",userId), {
    uid:userId,
    statistics:{
      bestStreak:0,
      currentStreak:0,
      totalWins:0,
      totalLosses:0,        
      inOneTurn:0,
      inTwoTurns:0,
      inThreeTurns:0,
      inFourTurns:0,
      inFiveTurns:0,
      inSixTurns:0,
    }
  })
}

generateUID(){
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < 9; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
  }
  return result;
}

}


const _FBUser = new FBUser();
export default _FBUser;
