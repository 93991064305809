import styles from './explotionAnimation.module.scss';	
import classNames from 'classnames/bind';	

const classes = classNames.bind(styles);

interface ExplotionAnimationProps{
    explode: boolean ;
}

const ExplotionAnimation:React.FC<ExplotionAnimationProps> = ({explode}:ExplotionAnimationProps) => {

const ExplodeAnimationClass = classes({
    explode:explode === true,
    dot:true
});

const BcckgroundExplotionAnimationClass = classes({
    boom:explode === false,
    boomExplode:explode === true
});


const LettersExplotionAnimationClass = classes({
    letters:explode === false,
    lettersExplode:explode === true
});

return(
    <>    
        <div className={BcckgroundExplotionAnimationClass}></div>
        
        <div className={ExplodeAnimationClass}></div>

        <div className={LettersExplotionAnimationClass}></div> 
    </>
)
}

export default ExplotionAnimation;