import {
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { auth } from "../../services/firebase/config";
import styles from "./modalStatistics.module.scss";

import { doc } from "firebase/firestore";
import { db } from "../../services/firebase/config";
import UserStatistics from "../UserStatistics/UserStatistics";

interface ModalStatisticsProps {
  show: boolean;
  onClose: () => void;
}

const ModalStatistics: React.FC<ModalStatisticsProps> = ({
  show,
  onClose,
}: ModalStatisticsProps) => {
  function dismiss() {
    onClose();
  }

  return (
    <>
      <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol></IonCol>
                <IonCol className={styles.modalTitle}>
                  <IonTitle >
                    Statistics
                  </IonTitle>
                </IonCol>
                <IonCol>
                  <IonButtons>
                    <IonButton className={styles.closeButton}  onClick={() => dismiss()}>
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className={styles.content}>
          <UserStatistics />
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalStatistics;
