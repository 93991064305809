import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB4iCBTDi4UFeqNtwSYKwCRpHq__Wqw2qY",
    authDomain: "boc-wordle.firebaseapp.com",
    projectId: "boc-wordle",
    storageBucket: "boc-wordle.appspot.com",
    messagingSenderId: "486102740064",
    appId: "1:486102740064:web:0960237c482e7cb613a705",
    measurementId: "G-1S0LQ88RQH"
  }

 

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

enableIndexedDbPersistence(db).then(data=>{
  console.log(data)
})
.catch((err) => {
  console.log(err)
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});