
import {  CLOSE_LOGIN, CONFIG_UPDATE, FINISHED_LOST, FINISHED_WON, LOADER_HIDE, LOADER_SHOW, PERFORM_LOGIN, PLAYING,  READY,  STARTING, } from "../constants/action-types";

const initialState = {
  status:PERFORM_LOGIN,
  gameId:"",
  userId: "",
  wordToGuess:"",
  completedWords:[],
  currentWord:"",
  turn:1, 
  
  isLoginOpen:false,
  previousState:null,

  dateStarted:0,
  dateFinished:0,
  totalTimerTimeSeconds:180,
  secondsConsumed:0,
  finishTimerDate:0,

  error: {}
};

export function GameReducer (state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case CONFIG_UPDATE:
      return { ...state, 
        totalTimerTimeSeconds:payload.timer * 60,        
      }
    case PERFORM_LOGIN:
      return { ...state, status:type, isLoginOpen:true, previousState:payload.previousState } 
    case CLOSE_LOGIN:
      return { ...state, status:payload.previousState,isLoginOpen:false } 
    case READY:
      return { 
        ...state, 
        status:type, 
        userId:payload.userId, 
        gameId:payload.gameId,  
        wordToGuess:"", 
        completedWords:[], 
        currentWord:"", 
        turn:1,  
        dateFinished:0,
        dateStarted:0,  
      } 
    case STARTING:
      return { 
        ...state, 
        status:type,         
        wordToGuess:payload.wordToGuess, 
        finishTimerDate:  new Date().setSeconds(new Date().getSeconds() + state.totalTimerTimeSeconds),  
        dateStarted:new Date().getTime(),
        secondsConsumed: (new Date().getTime() - state.dateStarted)/1000,
        error: {} 
      };
    case PLAYING:
      return { 
        ...state, 
        status:type, 
        currentWord:payload.currentWord,  
        completedWords:payload.completedWords, 
        secondsConsumed: (new Date().getTime() - state.dateStarted)/1000,
        turn:payload.turn,  
        error: {} 
      };
    case FINISHED_WON:
      return { 
        ...state, 
        status:type, 
        secondsConsumed: (new Date().getTime() - state.dateStarted)/1000,
        secondsLeft:payload.secondsLeft,
        completedWords:payload.completedWords,
        dateFinished:new Date().getTime(),
      }
    case FINISHED_LOST:
      return { 
        ...state, 
        status:type, 
        secondsLeft:payload.secondsLeft,
        completedWords:payload.completedWords, 
        dateFinished:new Date().getTime(),
      }
    case LOADER_SHOW:
        return { ...state, status:type, waiting:true }
    case LOADER_HIDE:
        return { ...state, status:type, waiting:false }
    default:
      return state;
  }
}