import classNames from 'classnames/bind';	
import { LetterStatus } from '../../enums/letterStatus';
import styles from './key.module.scss';	

const classes = classNames.bind(styles);

interface KeyProps{
  value: string;
  status: LetterStatus;
  animate?: boolean;
  onClick: (e:any) => void;
}

const Key: React.FC<KeyProps> = ({value,status,onClick,animate}:KeyProps) => {

  const LetterStatusClass = classes({
    correct:status === LetterStatus.correct,
    present:status === LetterStatus.present,
    absent:status === LetterStatus.absent,
    clear:status === LetterStatus.clear
  });

  return (
    <button  className={LetterStatusClass} onClick={onClick}>
        {value}
    </button>
  );
};

export default Key;