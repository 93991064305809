import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { cogOutline, heartOutline, helpOutline, logoGoogle, statsChartOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from 'react-router-dom';
import { auth } from '../services/firebase/config';
import  FBAuth  from '../services/firebase/auth';

const Login: React.FC = () => {
    const [email, setEmail] = useState("test@test.com");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);

    const history = useHistory();   
    
    const [errorInfo, setErrorInfo] = useState({
        showErrorToast:false,
        errMsg:""
    });    

    useEffect(() => {      
      if (user) history.push("/page");   

      return() => {

      }
    }, [user]);

    return (
        <IonPage>
          <IonHeader>
            <IonToolbar color="light">
              <IonButtons slot="start" />
              <IonTitle>Login</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            
    
            <IonItem>
              <IonLabel position="floating">Email Address</IonLabel>
              <IonInput
                type="email"
                onIonChange={(e:any) => {
                  setEmail(e.detail.value);
                }}
                name="email"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                type="password"
                onIonChange={(e:any) => {
                  setPassword(e.detail.value);
                }}
                name="password"
              />
            </IonItem>
            <div style={{ padding: 10, paddingTop: 20 }}>
              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={() => FBAuth.logInWithEmailAndPassword(email, password)}
              >
                Login
              </IonButton>
              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/register");
                }}
              >
                Create Account
              </IonButton>

              <IonButton
                expand="full"
                style={{ margin: 14 }}
                onClick={ FBAuth.signInWithGoogle }
              >
                <IonIcon icon={logoGoogle}></IonIcon>  Login With Google
              </IonButton>
              <div>
                <Link to="/reset">Forgot Password</Link>
              </div>              
            </div>
            <IonToast
              color="danger"
              isOpen={errorInfo.showErrorToast}
              onDidDismiss={() => setErrorInfo({ showErrorToast: false, errMsg: "" })}
              message={errorInfo.errMsg}
              duration={2000}
            />
          </IonContent>
        </IonPage>
      );
};

export default Login;
