import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLost,
  finishWon,
  play,
  initializeGame,
  ready,
  updateCurrentWord,
  updateConfig,
} from "../redux/actions";
import "./Game.css";
import styles from "./wordle.module.scss";

import RowCompleted from "./RowCompleted/RowCompleted";
import RowEmpty from "./RowEmpty/RowEmpty";
import RowCurrent from "./RowCurrent/RowCurrent";
import { useWindows } from "../hooks/useWindows";
import { validateKey } from "../helpers/validateKey";
import { keys } from "../helpers/validateKey";
import Dictionary, { Languages } from "../services/dictionary";
import Keyboard from "./Keyboard/Keyborad";
import ModalTutorial from "./ModalTutorial/ModalTutorial";
import TimerDynamite from "./TimerDynamite/TimerDynamite";
import {
  FINISHED_LOST,
  FINISHED_WON,
  PLAYING,
  READY,
  STARTING,
} from "../redux/constants/action-types";

import Confetti from "react-confetti";
import ModalWon from "./ModalWon/ModalWon";
import ModalLost from "./ModalLost/ModalLost";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory, useParams } from "react-router";
import { auth } from "../services/firebase/config";

import Sounds from "../services/sounds";
import ExplotionAnimation from "./TimerDynamite/ExplosionAnimation/ExplotionAnimation";
import ModalShareResults from "./ModalShareResult/ModalShareResult";

const ExploreContainer: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showWonModal, setShowWonModal] = useState<boolean>(false);
  const [showLostModal, setShowLostModal] = useState<boolean>(false);
  const [showedTutorial, setShowedTutorial] = useState<boolean>(false);
  const [showShareResult, setShowShareResult] = useState<boolean>(false);
  const [timerMinutes, setTimerMinutes] = useState<string>("3");
  const [forceExplotion, setForceExplotion] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { innerWidth: width, innerHeight: height } = window;

  const isReady = useSelector((states: any) => {
    return states.game.status === READY;
  });

  const isStarting = useSelector((states: any) => {
    return states.game.status === STARTING;
  });

  const isPlaying = useSelector((states: any) => {
    return states.game.status === PLAYING;
  });

  const isFinishedWon = useSelector((states: any) => {
    return states.game.status === FINISHED_WON;
  });

  const isFinishedLost = useSelector((states: any) => {
    return states.game.status === FINISHED_LOST;
  });

  const wordToGuess = useSelector((states: any) => {
    return states.game.wordToGuess || "";
  });

  const completedWords: string[] = useSelector((states: any) => {
    return states.game.completedWords || [];
  });

  const turn: number = useSelector((states: any) => {
    return states.game.turn;
  });

  const currentWord: string = useSelector((states: any) => {
    return states.game.currentWord || "";
  });

  const storedUserId: string = useSelector((states: any) => {
    return states.game.userId || "";
  });

  const storedGameId: string = useSelector((states: any) => {
    return states.game.gameId || "";
  });

  const finishTimerDate = useSelector((states: any) => {
    return states.game.finishTimerDate || 0;
  });

  

  useWindows("keydown", handleKeyDown);

  function handleKeyDown(event: KeyboardEvent) {
    const key = event.key.toUpperCase();
    onKeyPressed(key);
  }

  async function onKeyPressed(key: string) {
    if (!(isPlaying || isStarting || isReady)) {
      console.log("not playing");
      return;
    }

    if (key === "BACKSPACE" && currentWord.length > 0) {
      onDelete();
      return;
    }

    if (key === "ENTER" && currentWord.length === 5 && turn <= 6) {
      onEnter();
     // Sounds.play('explotion');
      return;
    }

    if (currentWord.length >= 5) return;

    if (validateKey(key)) {
      if (isReady) {
        console.log("Init");
        setForceExplotion(false);
        setShowTutorial(false);
        dispatch(await initializeGame(storedUserId, storedGameId));
      }
      onInput(key);
      return;
    }
  }

  async function onInput(letter: string) {
    const newWord = currentWord + letter;
    dispatch(
      updateCurrentWord({
        completedWords: completedWords,
        turn: turn,
        currentWord: newWord,
      })
    );
  }

  async function onDelete() {
    const newWord = currentWord.slice(0, -1);

    dispatch(
      updateCurrentWord({
        completedWords: completedWords,
        turn: turn,
        currentWord: newWord,
      })
    );
  }

  async function onEnter() {
    if (!isPlaying && !isStarting) {
      return;
    }

    if (currentWord === wordToGuess) {
      setShowWonModal(true);
      Sounds.play("congrats");
      dispatch(
        finishWon({
          timerMinutes: timerMinutes,
          secondsLeft: getSecondsLeft(),
          gameId: storedGameId,
          userId: storedUserId,
          completedWords: [...completedWords, currentWord],
          turn: turn,
          currentWord: "",
          wordToGuess: wordToGuess,
          finish: true,
          isWon: true,
        })
      );
      return;
    }

    if (turn === 6) {
      console.log("turn 6");
      userLost();
      return;
    }

    const validWord = await Dictionary.isValidWord(
      currentWord,
      Languages.english
    );

    if (currentWord.length === 5 && !validWord) {
      alert("Not a valid word");
      return;
    }

    dispatch(
      play({
        timerMinutes: timerMinutes,
        secondsLeft: getSecondsLeft(),
        gameId: storedGameId,
        userId: storedUserId,
        completedWords: [...completedWords, currentWord],
        turn: turn + 1,
        currentWord: "",
        wordToGuess: wordToGuess,
        finish: false,
        isWon: false,
      })
    );
  }

  async function nextWord() {
    setShowLostModal(false);
    dispatch(ready(storedUserId));
  }

  function userLost() {
    Sounds.play('explotion');	
    setTimeout(() => {
      setShowLostModal(true);
    }, 3000);
    setForceExplotion(true);
    if (isPlaying) {
      dispatch(
        finishLost({
          timerMinutes: timerMinutes,
          secondsLeft: getSecondsLeft(),
          gameId: storedGameId,
          userId: storedUserId,
          completedWords: [...completedWords, currentWord],
          turn: turn,
          currentWord: "",
          wordToGuess: wordToGuess,
          finish: true,
          isWon: false,
        })
      );
    }
  }

  useEffect(()=>{
    if(isFinishedLost)
      Sounds.play('explotion');
  },[isFinishedLost])


  useEffect(() => {

    if (!isPlaying && !isStarting) {    
      
      console.log("setting timer....")
      if (window.location) {
        let timerValue = new URLSearchParams(window.location.search).get(
          "timer"
        );  
        if (timerValue) 
          setTimerMinutes(timerValue)
        else
          setTimerMinutes("3");          
      }

      dispatch(ready(storedUserId));
      if (isReady && !showedTutorial) {
        setShowTutorial(true);
        setShowedTutorial(true);
      }
    }

    if (user?.uid) {
      dispatch(ready(user?.uid));
    }
  }, [user]);

  function getSecondsLeft() {
    return ((finishTimerDate - new Date().getTime()) / 1000).toFixed(0);
  }

  return (
    <div>
      <ModalShareResults
        show={showShareResult}
        onClose={() => {
          setShowShareResult(false);
          nextWord();
        }}
      />
      <ModalTutorial
        show={showTutorial}
        onClose={() => {
          setShowTutorial(false);
        }}
      />
      <ModalWon
        show={showWonModal}
        onClose={() => {
          nextWord();
          setShowWonModal(false);
        }}
        onShareResults={() => {
          setShowWonModal(false);
          setShowShareResult(true);
        }}
      />
      <ModalLost
        show={showLostModal}
        onClose={() => {
          nextWord();
          setShowLostModal(false);
        }}
        onShareResults={() => {
          setShowWonModal(false);
          setShowShareResult(true);
        }}
      />
      <TimerDynamite
        forceExplotion={forceExplotion}
        onFinish={() => {
          userLost();
        }}
      />
      <div className={styles.mainContainer}>
        {completedWords.map((word, i) => (
          <RowCompleted key={i} word={word} animate={true} />
        ))}

        {isPlaying || isStarting || isReady ? (
          <RowCurrent word={currentWord} />
        ) : null}

        {Array.from(Array(6 - turn)).map((_, i) => (
          <RowEmpty key={i + 1} />
        ))}
      </div>
      <Keyboard keys={keys} onKeyPressed={onKeyPressed} />
      <Confetti
        hidden={!isFinishedWon}
        run={isFinishedWon}
        colors={["#9A2ED1", "#CD94EB", "#8432EC", "#281830"]}
        width={width}
        height={height}
      />
    </div>
  );
};

export default ExploreContainer;
