import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  closeOutline,
  cogOutline,
  heartOutline,
  helpOutline,
  statsChartOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Game from "../components/Game";
import { auth } from "../services/firebase/config";
import "./Page.css";
import Logo from "../assets/logo.png";
import ModalTutorial from "../components/ModalTutorial/ModalTutorial";
import ModalStatistics from "../components/ModalStatistics/ModalStatistics";
import { isPlatform } from "@ionic/react";

const Page: React.FC = () => {
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showStatistics, setShowStatistics] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <ModalTutorial
        show={showTutorial}
        onClose={() => {
          setShowTutorial(false);
        }}
      />
      <ModalStatistics
        show={showStatistics}
        onClose={() => {
          setShowStatistics(false);
        }}
      />
      <IonPage>
        <IonHeader class="ion-no-border">
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButtons>
                    {showMenu ? <IonMenuButton /> : null}
                    <IonButton
                      onClick={() => {
                        setShowTutorial(true);
                      }}
                      className="header-text"
                    >
                      {isPlatform("desktop") ? (
                        "How to play?"
                      ) : (
                        <IonIcon icon={helpOutline} id="open-modal" />
                      )}
                    </IonButton>
                  </IonButtons>
                </IonCol>
                <IonCol>
                  <IonTitle className="logo-wraper">
                    <IonImg className="header-logo" src={Logo} alt="" />
                  </IonTitle>
                </IonCol>
                <IonCol>
                  <IonButtons className="float-right">
                    <IonButton 
                      className="header-icon"
                      onClick={() => {
                        setShowStatistics(true);
                      }}
                    >
                      <IonIcon icon={statsChartOutline} />
                    </IonButton>
                    <IonButton className="header-icon">
                      <IonIcon icon={cogOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <Game />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Page;
