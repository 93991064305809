import classNames from 'classnames/bind';	
import { useEffect, useState } from 'react';
import { BoxStatus } from '../../enums/boxStatus';
import styles from './boxCompleted.module.scss';	

const classes = classNames.bind(styles);

interface BoxCompletedProps{
  value: string;
  status: BoxStatus;
  animate?: boolean;
  pos: number;
}

const BoxCompleted: React.FC<BoxCompletedProps> = ({value,status,pos}:BoxCompletedProps) => {

    const [rotate, setRotate] = useState<boolean>(false);  

  const BoxStatusClass = classes({
    correct:status === BoxStatus.correct,
    present:status === BoxStatus.present,
    absent:status === BoxStatus.absent,
    empty:status === BoxStatus.empty,
    edit:status === BoxStatus.edit,
  });

  useEffect(() => {     
    setTimeout(() => {
        setRotate(true)
    }, 300*pos);    
  },[])

  return (
    <div className={styles.flipCard}>
        <div  className={rotate ?  styles.flipCardInner : styles.flipCardInnerRotated}>
            <div className={styles.flipCardBack}>
                {value}
            </div>
            <div className={BoxStatusClass}>
                {value}
            </div>
        </div>
    </div>
  );
};

export default BoxCompleted;