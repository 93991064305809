import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Register from './pages/Register';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import { useState } from 'react';

interface GameProps extends RouteComponentProps<{
    time: string;
  }> {}


setupIonicReact();

const App: React.FC = () => {

  const [showMenu, setShowMenu] = useState<boolean>(false);
  
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" when="true">
        {showMenu?<Menu />:null}
          
          <IonRouterOutlet id="main"> 
            <Route path="/" exact={true}>
              <Page />
            </Route>
            <Route path="/page" exact={true}>
              <Page />
            </Route>           
            <Route path="/login" exact={true}>
              <Login /> 
            </Route>
            <Route path="/register" exact={true}>
              <Register />
            </Route>
            <Route path="/reset" exact={true}>
              <ResetPassword />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
