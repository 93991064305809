import {
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { auth } from "../../services/firebase/config";
import styles from "./modalShareResult.module.scss";

import { doc } from "firebase/firestore";
import { db } from "../../services/firebase/config";
import UserStatistics from "../UserStatistics/UserStatistics";
import ShareResults from "../ShareResults/ShareResults";

interface ModalShareResultsProps {
  show: boolean;
  onClose: () => void;
}

const ModalShareResults: React.FC<ModalShareResultsProps> = ({
  show,
  onClose,
}: ModalShareResultsProps) => {

  function dismiss() {
    onClose();
  }

  

  return (
    <>
      <IonModal backdrop-dismiss={false} isOpen={show} className="modal">
        <IonContent className={styles.content}>
          <IonToolbar>
            <IonTitle className={styles.modalTitle}>Share Result</IonTitle>
            <IonButtons slot="end">
              <IonButton color="light" onClick={() => dismiss()}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <ShareResults />
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalShareResults;
