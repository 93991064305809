import { initializeApp } from "firebase/app";
import {
 GoogleAuthProvider,
 getAuth,
 signInWithPopup,
 signInWithEmailAndPassword,
 createUserWithEmailAndPassword,
 sendPasswordResetEmail,
 signOut
} from "firebase/auth";
import {
 getFirestore,
 query,
 getDocs,
 collection,
 where,
 addDoc,
 setDoc,
 doc,
}from "firebase/firestore";
import { auth, googleProvider, db } from "./config";

class FBAuth { 

  async  signInWithGoogle(){

    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await setDoc(doc(db, "users",user.uid), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            tutorial: true,
            statistics:{
              totalWins:0,
              totalLosses:0,
              inOneTurn:0,
              inTwoTurns:0,
              inThreeTurns:0,
              inFourTurns:0,
              inFiveTurns:0,
              inSixTurns:0,
            }
          });
        }
        resolve(user);
      } catch (err:any) {
        return this.translateErrorCode(err.code)
      }

    });
  };

   async  logInWithEmailAndPassword  (email:string, password:string) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err:any) {
      return this.translateErrorCode(err.code)
    }
  };

   async  registerWithEmailAndPassword (name:string, email:string, password:string){
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      await addDoc(collection(db, "users",user.uid), {
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err:any) {
      return this.translateErrorCode(err.code)
    }
  };


   async  sendPasswordReset(email:any) {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err:any) {
      return this.translateErrorCode(err.code)
    }
  };

  async  logout(){    
    signOut(auth);
  };

  translateErrorCode(code:string){
    if(code === "auth/account-exists-with-different-credential"){
      return "You have already registered with a different provider. Please sign in with that provider.";
    }
    if(code === "auth/user-not-found"){
      return "User not found. Please register.";
    }
    if(code === "auth/wrong-password"){
      return "Wrong password. Please try again.";
    }
    if(code === "auth/popup-closed-by-user"){
      return "Popup closed by user.";
    }
    if(code === "auth/popup-blocked"){
      return "Popup blocked. Please enable popups for this site.";
    }
    if(code === "auth/operation-not-allowed"){
      return "Operation not allowed. Please enable popups for this site.";
    }
    if(code === "auth/auth-domain-config-required"){
      return "auth-domain-config-required";
    }
    if(code === "auth/invalid-password"){
      return "Password is invalid. Please try again.";
    }
    if(code === "auth/invalid-email"){
      return "Email is invalid. Please try again.";
    }
    if(code === "auth/weak-password"){
      return "Password is too weak. Please try again.";
    }
    if(code === "auth/email-already-in-use"){
      return "Email is already in use. Please try again.";
    }
    if(code === "auth/internal-error"){
      return "Invalida data, please try again.";
    }
    return code;
  }
}

const _FBAuth = new FBAuth();
export default _FBAuth;