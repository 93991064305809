import classNames from 'classnames/bind';	
import { BoxStatus } from '../../enums/boxStatus';

import styles from './box.module.scss';	

const classes = classNames.bind(styles);

interface BoxProps{
  value: string;
  status: BoxStatus;
  animate?: boolean;
  pos?: number;
}

const Box: React.FC<BoxProps> = ({value,status}:BoxProps) => {

  const BoxStatusClass = classes({
    correct:status === BoxStatus.correct,
    present:status === BoxStatus.present,
    absent:status === BoxStatus.absent,
    empty:status === BoxStatus.empty,
    edit:status === BoxStatus.edit,
  });

  return (
    <div className={BoxStatusClass}>
      {value}
    </div>
  );
};

export default Box;