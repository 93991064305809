import { createAction } from "@reduxjs/toolkit";
import FBUser from "../../services/firebase/user";
import {  ALERT, CONFIG_UPDATE, FINISHED_LOST, FINISHED_WON, LOADER_HIDE, LOADER_SHOW, PERFORM_LOGIN, PLAYING, READY, STARTING} from "../constants/action-types";
import  Dictionary, { Languages }  from '../../services/dictionary';


export interface PlayInterface{
  timerMinutes:string;
  secondsLeft:string;
  gameId: string;	
  userId: string,
  completedWords:any,
  turn: number,
  currentWord:string,
  wordToGuess:string,
  finish:boolean,
  isWon:boolean,
}

export const  doLogin = (previousState:any) => {
  return {
    payload: {
      previousState: previousState
    },
    type: PERFORM_LOGIN
  }
}


export const closeLogin = (previousState:any) => {
  console.log(previousState)
  return {
    payload: {},
    type: previousState
  }
}

export const showLoading = (payload: any) => {
  return {
    type:LOADER_SHOW,
    payload
  }
}

export const hideLoading = (payload: any) => {
  return {
    type:LOADER_HIDE,
    payload
  }
}

export const ready = (userId: any) => {   
    
  if(!userId ){
    userId = "Unknow_"+FBUser.generateUID()
    FBUser.generateStatistics(userId);
  }
  else{
    FBUser.checkIfUserExists(userId).then(res => {
      if(!res){
        FBUser.generateStatistics(userId);
      } 
    })
  }    

  let payload:any = {
    gameId : FBUser.generateUID(),    
    userId: userId,
  }

  return {
    type:READY,
    payload
  }
  
}

export const initializeGame =  async (userId:string,gameId:string) => { 
  FBUser.startNewGame(userId,gameId)

  
  let payload = {
    wordToGuess: await Dictionary.getRandomWordToGuess(Languages.english),
  }

  return {
    type:STARTING,
    payload
  } 
}


export const play =  (data: PlayInterface) => {

  FBUser.updateGame(data)
  
  let payload = {
    currentWord:data.currentWord,  
    completedWords:data.completedWords, 
    turn:data.turn,  
  }
  

  return {
    type: PLAYING,
    payload
  }
}

export const updateCurrentWord = (payload:any) => {  
  return {
    type: PLAYING,
    payload
  }
}

export const finishWon = (payload: PlayInterface) => {
  FBUser.updateGame(payload)  
  return {
    type: FINISHED_WON,
    payload
  }
}

export const finishLost = (payload: PlayInterface) => {
  FBUser.updateGame(payload)  
  return {
    type: FINISHED_LOST,
    payload
  }
}

export const updateConfig = (payload: any) => {
  console.log(payload)
  return {
    type: CONFIG_UPDATE,
    payload
  }
}
