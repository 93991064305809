import {
  IonModal,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { auth } from "../../services/firebase/config";
import styles from "./modalWon.module.scss";

import { doc } from "firebase/firestore";
import { db } from "../../services/firebase/config";
import UserStatistics from "../UserStatistics/UserStatistics";
import { useGenerateShareText } from "../../hooks/useGenerateText";
import { Share } from "@capacitor/share";

interface ModalWonProps {
  show: boolean;
  onClose: () => void;
  onShareResults: () => void;
}

const ModalWon: React.FC<ModalWonProps> = ({
  show,
  onClose,
  onShareResults,
}: ModalWonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { text } = useGenerateShareText();

  async function share() {
    console.log(text);
    await Share.share({
      title: "My Explodle Result!",
      text: text,
      url: "http://explodle.com/",
      dialogTitle: "Share my new achievement",
    });
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setOpen(true);
      }, 1500);
    } else {
      setOpen(false);
    }
  }, [show]);

  function dismiss() {
    onClose();
  }

  return (
    <>
      <IonModal backdrop-dismiss={false} isOpen={open} className="modal">
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol></IonCol>
                <IonCol className={styles.modalTitle}>
                  <IonTitle>
                    <IonTitle className={styles.modalTitle}>
                      &#128562;{" "}
                    </IonTitle>
                  </IonTitle>
                </IonCol>
                <IonCol>
                  <IonButtons>
                    <IonButton
                      className={styles.closeButton}
                      onClick={() => dismiss()}
                    >
                      <IonIcon icon={closeOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className={styles.content}>
          <p className={styles.contentTitle}>Spectacular!</p>
          <UserStatistics />
          <IonButton
            className="share-button"
            color="primary"
            onClick={() => share()}
          >
            Share Results
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ModalWon;
